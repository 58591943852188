import { Carousel, CarouselSlide } from "@vp/swan"
import React, { useEffect, useState } from "react"
import { PROMOBAR_SKINS, CommsBarApiResponse } from "./types"
import { FlexBox } from "@vp/swan"
import { Content } from "./Content"
import { MARKETING_COLORS } from "@vp/marketing-colors"
import { DebugContainer } from "./DebugContainer"
import { fireImpression } from "@vp/ab-reader"
import { trackEvent } from "../utils/trackingUtils"
import { useI18n } from "../localization/LanguageContext"

const SKIN_COLOR_MAPPING: Record<string, string> = {
  [PROMOBAR_SKINS.STANDARD]: "grey100",
  [PROMOBAR_SKINS.DISCOUNT]: "green100",
  [PROMOBAR_SKINS.HOLIDAY]: "holidayRed1",
  [PROMOBAR_SKINS.HOLIDAY_GREEN]: "holidayGreen2",
  [PROMOBAR_SKINS.HOLIDAY_BEIGE]: "holidayBeige1",
  [PROMOBAR_SKINS.INVERSE]: "black900",
}

const COLORS = {
  ...MARKETING_COLORS,
  grey100: { code: "#f8f8f8", mode: { dark: false, standard: true } },
  green100: { code: "#e7f3f0", mode: { dark: false, standard: true } },
}

type MessageCarouselProps = {
  data: CommsBarApiResponse
  pageType: string
}
export const MessageCarousel = ({ data, pageType }: MessageCarouselProps) => {
  const [mounted, setMounted] = useState(false)
  const [debugValue, setDebugValue] = useState(false)
  const { translate } = useI18n()

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    const idValue: string | null = urlParams.get('debugPage')

    setDebugValue(idValue === "true")
  }, [])

  useEffect(() => {
    setMounted(true)
  }, [data])

  const { a11yText, messages, contentfulLinkWithoutCoupon, contentfulLinkWithCoupon } = data
  const trackingEnabled = data.promobarConfiguration?.tracking !== "none"


  const trackMessages = (currentSlideIndex: number) => {
    if (mounted && trackingEnabled) {
      const message = messages[currentSlideIndex]
      if (message?.content) {
        let eventDetail

        if (message?.couponCode) {
          eventDetail = `view_promoBarMessage_C`
        } else if (message?.source === 'map') {
          eventDetail = `view_promoBarMessage_M`
        } else {
          const couponExists = messages.some(msg => msg.couponCode)
          const mapExists = messages.some(msg => msg.source === 'map')

          let regularMessageIndex = currentSlideIndex


          if (couponExists) {
            regularMessageIndex--
          }
          if (mapExists) {
            regularMessageIndex--
          }

          eventDetail = `view_promoBarMessage_R${regularMessageIndex + 1}`
        }

        trackEvent({
          eventName: 'Message Displayed', properties: {
            eventDetail
          }
        })
      }
    }
  }


  useEffect(() => {
    if (mounted && trackingEnabled) {
      messages.forEach((message, index) => {
        if (message.abTestServerData && pageType) {
          const abTestData = message.abTestServerData
          fireImpression(abTestData.experimentKey, abTestData.variationKey, { pageName: pageType })
        }
      })
    }
  }, [mounted])

  useEffect(() => {
    trackMessages(0)
  }, [mounted])

  const afterChangeHandler = (currentSlideIndex: number) => {
    trackMessages(currentSlideIndex)
  }

  const messageElements = (oneMessage?: boolean) => {
    let i = 0
    const elements = messages.map((message, index) => {
      const { skin, content, preceedingWord, copyIcon } = message

      if (!message.content) {
        return undefined
      }

      if (oneMessage && i > 0) {
        return
      }

      const colorKey = SKIN_COLOR_MAPPING[skin] as keyof typeof COLORS
      const color = COLORS[colorKey] ?? COLORS["grey100"]
      i++
      return (
        <CarouselSlide style={{ width: "100%" }} key={index}>
          <FlexBox
            fontWeight="bold"
            fontSize="small"
            style={{
              minHeight: 60,
              height: "100%",
              paddingLeft: "20px",
              paddingRight: "20px",
              backgroundColor: color.code,
            }}
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            darkMode={color.mode.dark}
          >
            <Content
              content={content}
              preceedingWord={preceedingWord}
              copyIcon={copyIcon}
            ></Content>
          </FlexBox>
        </CarouselSlide>
      )
    })

    return elements.filter(x => x)
  }
  if (mounted) {
    return (
      <>
        <Carousel
          slidesToShow={1}
          progressIndicator="none"
          fade
          speed={400}
          infinite
          pauseOnHover
          className={`sitewide-promobar-carousel coupon-test_selectable-text`}
          initialSlide={0}
          skin="promo-bar"
          accessibleTextForPrevious={translate("previousMessage")}
          accessibleTextForNext={translate("nextMessage")} //   accessibleTextForPrevious={a11yText?.previousMessage as string}
          afterChange={afterChangeHandler}
        >
          {messageElements()}
        </Carousel>
        {debugValue && <DebugContainer contentfulLinkWithCoupon={contentfulLinkWithCoupon} contentfulLinkWithoutCoupon={contentfulLinkWithoutCoupon} />}
      </>
    )
  } else {
    return <>{messageElements(true)}</>
  }
}
